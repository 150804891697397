/*=========================================================================================
  File Name: themeConfig.js
  Description: Theme configuration
  ----------------------------------------------------------------------------------------
  Item Name: Modern Admin - Clean Bootstrap 4 Dashboard HTML Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

// MAIN COLORS - VUESAX THEME COLORS
let colors = {
	primary: '#09548C',
	success: '#00a79d',
	danger: '#ef5350',
	warning: '#FF9F43',
	dark: '#1E1E1E',

	caf: '#114B5F',
	cv: '#49306b',
	ps: '#028090',
	interview: '#ECA400',
	programs: '#456990',
}

import Vue from 'vue'
import Vuesax from 'vuesax'
Vue.use(Vuesax, { theme:{ colors } });


// CONFIGS
const themeConfig = {
	theme: 'light',						// options[String]: 'light'(default), 'dark', 'semi-dark'
	sidebarCollapsed: false,			// options[Boolean]: true, false(default)
	navbarColor: "#fff",				// options[String]: HEX color / rgb / rgba / Valid HTML Color name - (default: #fff)
	navbarType: "floating",				// options[String]: floating(default) / static / sticky / hidden
	footerType: "static",				// options[String]: static(default) / sticky / hidden
	routerTransition: 'zoom-fade',		// options[String]: zoom-fade / slide-fade / fade-bottom / fade / zoom-out / none(default)
	disableCustomizer: false,			// options[Boolean]: true, false(default)
}

export default themeConfig
