/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  Object Strucutre:
                    path => router path
                    name => router name
                    component(lazy loading) => component to load
                    meta : {
                      rule => which user can have access (ACL)
                      breadcrumb => Add breadcrumb to specific page
                      pageTitle => Display title besides breadcrumb
                    }
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: '/',
    routes: [
    {
      path: '/email-confirmation/:id',
      component: () => import('./views/EmailConfirmation.vue'),
      name: 'Email Confirmation',
      meta: {
        auth: undefined,
        pageTitle: 'Email Confirmation'
      }
    },
    {
      path: '/one-more-test',
      component: () => import('./views/OneMoreTest.vue'),
      name: 'one_more_test',
      meta: {
        auth: undefined,
        pageTitle: 'One More Test'
      }
    },
    {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
        path: '',
        component: () => import('./layouts/main/Main.vue'),
        children: [
      // =============================================================================
      // Theme Routes
      // =============================================================================
          {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
            meta: {
              auth: undefined
            }
          },
          {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
            name: 'Login',
            meta: {
              auth: undefined,
              pageTitle: 'Login'
            }
          },
          {
            path: '/sjsm-login',
            component: () => import(/* webpackChunkName: "login" */ './views/LoginSjsm.vue'),
            name: 'Login',
            meta: {
              auth: undefined,
              pageTitle: 'SJSM Login'
            }
          },
          {
            path: '/register',
            component: () => import(/* webpackChunkName: "register" */ './views/Register.vue'),
            name: 'Register',
            meta: {
              auth: false
            }
          },
          {
            path: '/profile',
            component: () => import(/* webpackChunkName: "profile" */ './views/Profile.vue'),
            props: { method: 'edit' },
            name: 'Profile',
            meta: {
              auth: {roles: ['admin','user', 'writer', 'data entry', 'admin sjsm'], redirect: {path: '/login'}, forbiddenRedirect: '/403'}
            }
          },
          {
            path: '/reset-password',
            component: () => import(/* webpackChunkName: "forgot-password" */ './views/ForgotPassword.vue'),
            props: { method: 'edit' },
            name: 'reset-password',
            meta: {
              auth: false,
              pageTitle: 'Reset Password'
            }
          },
          {
            path: '/reset-password/:token',
            component: () => import(/* webpackChunkName: "forgot-password-form" */ './views/ResetPasswordForm.vue'),
            name: 'reset-password-form',
            meta: {
              auth: false,
              pageTitle: 'Reset Password'
            }
          },
          {
            path: '/test',
            component: () => import(/* webpackChunkName: "test" */ './views/Test.vue'),
            props: { method: 'index' },
            name: 'test',
            meta: {
              auth: undefined,
              pageTitle: 'Test'
            }
          },
          // ADMIN ROUTES
          {
            path: '/users',
            name: 'users',
            component: () => import(/* webpackChunkName: "users" */ './views/admin/Users.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Users'
            }
          },
          {
            path: '/users/create',
            name: 'users.create',
            component: () => import(/* webpackChunkName: "users" */ './views/admin/Users.vue'),
            props: { method: 'create' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Users'
            }
          },
          {
            path: '/users/:id/edit',
            name: 'users.edit',
            component: () => import(/* webpackChunkName: "users" */ './views/admin/Users.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Users'
            }
          },
          {
            path: '/attending-manager',
            name: 'doctors',
            component: () => import(/* webpackChunkName: "doctors" */ './views/admin/Doctors.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Attending Manager'
            }
          },
          {
            path: '/attending-manager/create',
            name: 'doctors.create',
            component: () => import(/* webpackChunkName: "doctors" */ './views/admin/Doctors.vue'),
            props: { method: 'create' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Attending Manager'
            }
          },
          {
            path: '/attending-manager/:id/edit',
            name: 'doctors.edit',
            component: () => import(/* webpackChunkName: "doctors" */ './views/admin/Doctors.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Attending Manager'
            }
          },
          {
            path: '/email-templates',
            name: 'email_templates',
            component: () => import(/* webpackChunkName: "email-templates" */ './views/admin/EmailTemplates.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Email templates'
            }
          },
          {
            path: '/email-templates/:id/edit',
            name: 'email_templates.edit',
            component: () => import(/* webpackChunkName: "email-templates" */ './views/admin/EmailTemplates.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Email templates'
            }
          },
          {
            path: '/admin/todo',
            name: 'admin_todo',
            component: () => import(/* webpackChunkName: "admin-todo" */ './views/admin/AdminTodo.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Applicant Tasks'
            }
          },
          {
            path: '/admin-todo/:id/edit',
            name: 'admin_todo.edit',
            component: () => import(/* webpackChunkName: "admin-todo" */ './views/admin/AdminTodo.vue'),
            props: { method: 'show' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Applicant Tasks'
            }
          },
          {
            path: '/email-queue',
            name: 'email_queue',
            component: () => import(/* webpackChunkName: "email-queue" */ './views/admin/EmailQueue.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Email Queue'
            }
          },
          {
            path: '/email-queue/:id/edit',
            name: 'email_queue.edit',
            component: () => import(/* webpackChunkName: "email-queue" */ './views/admin/EmailQueue.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Email Queue'
            }
          },
          {
            path: '/residency-programs',
            name: 'programs',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/Programs.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Programs'
            }
          },
          {
            path: '/f-programs',
            name: 'fellowship-programs',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/Programs.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Fellowship Programs'
            }
          },
          {
            path: '/f-programs/create',
            name: 'fellowship-programs.create',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/FellowshipPrograms.vue'),
            props: { method: 'create', programType : 'fellowship'  },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Fellowship Programs'
            }
          },
          {
            path: '/r-programs',
            name: 'research-programs',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/OtherPrograms.vue'),
            props: { method: 'index', programType : 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research Programs'
            }
          },
          {
            path: '/research-programs/:id/edit',
            name: 'research-programs.edit',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/OtherPrograms.vue'),
            props: { method: 'edit', programType : 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research Programs'
            }
          },
          {
            path: '/research-programs/create',
            name: 'research-programs.create',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/OtherPrograms.vue'),
            props: { method: 'create', programType : 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research Programs'
            }
          },
          {
            path: '/o-programs',
            name: 'observership-programs',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/OtherPrograms.vue'),
            props: { method: 'index', programType : 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observership Programs'
            }
          },
          {
            path: '/observership-programs/:id/edit',
            name: 'observership-programs.edit',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/OtherPrograms.vue'),
            props: { method: 'edit', programType : 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observership Programs'
            }
          },
          {
            path: '/observership-programs/create',
            name: 'observership-programs.create',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/OtherPrograms.vue'),
            props: { method: 'create', programType : 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observership Programs'
            }
          },
          {
            path: '/programs/:id/edit',
            name: 'programs.edit',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/Programs.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Programs'
            }
          },
          {
            path: '/f-programs/:id/edit',
            name: 'fellowship-programs.edit',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/Programs.vue'),
            props: { method: 'edit', listType : 'fellowship' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Programs'
            }
          },
          {
            path: '/programs/create',
            name: 'programs.create',
            component: () => import(/* webpackChunkName: "programs" */ './views/admin/Programs.vue'),
            props: { method: 'create' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Programs'
            }
          },
          {
            path: '/scraper',
            name: 'scraper',
            component: () => import(/* webpackChunkName: "scraper" */ './views/admin/Scraper.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Scraper'
            }
          },
          {
            path: '/f-scrpr',
            name: 'f-scraper',
            component: () => import(/* webpackChunkName: "scraper" */ './views/admin/FellowshipScraper.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Fellowship Scraper'
            }
          },
          {
            path: '/externships',
            name: 'externships',
            component: () => import(/* webpackChunkName: "externships" */ './views/admin/Externships.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Externships'
            }
          },
          {
            path: '/externships/create',
            name: 'externships.create',
            component: () => import(/* webpackChunkName: "externships" */ './views/admin/Externships.vue'),
            props: { method: 'create' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Externships'
            }
          },
          {
            path: '/externships/:id/edit',
            name: 'externships.edit',
            component: () => import(/* webpackChunkName: "externships" */ './views/admin/Externships.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Externships'
            }
          },
          {
            path: '/personal-statements',
            name: 'personal_statments',
            component: () => import(/* webpackChunkName: "personal-statements" */ './views/admin/PersonalStatements.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Personal Statements'
            },
          },
          {
            path: '/personal-statements/:id/edit',
            name: 'personal_statments.edit',
            component: () => import(/* webpackChunkName: "pscv" */ './views/PsCv.vue'),
            props: { method: 'edit', type: 'ps' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Personal Statements'
            }
          },
          {
            path: '/interviews',
            name: 'interview_preparation_service',
            component: () => import(/* webpackChunkName: "interview-services" */ './views/admin/InterviewServices.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Interview Preparation Services'
            },
          },
          {
            path: '/interviews/:id/edit',
            name: 'interview_preparation_service.edit',
            component: () => import(/* webpackChunkName: "interview-services-edit" */ './views/admin/InterviewServicesEdit.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Interview Preparation Services'
            },
          },
          {
            path: '/cv',
            name: 'cv',
            component: () => import(/* webpackChunkName: "cvs" */ './views/admin/CVs.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry','writer'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'CVs'
            },
          },
          {
            path: '/cv/:id/edit',
            name: 'cv.edit',
            component: () => import(/* webpackChunkName: "ps-cv" */ './views/PsCv.vue'),
            props: { method: 'edit', type: 'cv' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'CVs'
            }
          },
          {
            path: '/medical-liabilities',
            name: 'medical_liabilities',
            component: () => import(/* webpackChunkName: "medical-liabilities" */ './views/admin/MedicalLiabilities.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Medical Liabilities'
            },
          },
          {
            path: '/medical-liabilities/:id/edit',
            name: 'medical_liabilities.edit',
            component: () => import(/* webpackChunkName: "medical-liabilities-edit" */ './views/admin/MedicalLiabilitiesEdit.vue'),
            props: { method: 'edit', path: 'ml', singular: 'medical liability' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Medical Liabilities'
            }
          },
          {
            path: '/program-emailer',
            name: 'program_emailer',
            component: () => import(/* webpackChunkName: "program-emailer" */ './views/admin/Emailer.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Program E-mailer'
            },
          },
          {
            path: '/f-emailer',
            name: 'fellowship_emailer',
            component: () => import(/* webpackChunkName: "fellowship-emailer" */ './views/admin/Emailer.vue'),
            props: { method: 'index', type: 'fellowship' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Fellowship E-mailer'
            },
          },
          {
            path: '/r-emailer',
            name: 'research_emailer',
            component: () => import(/* webpackChunkName: "research-emailer" */ './views/admin/OtherEmailer.vue'),
            props: { method: 'index', type: 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research E-mailer'
            },
          },
          {
            path: '/o-emailer',
            name: 'observership_emailer',
            component: () => import(/* webpackChunkName: "observerships-emailer" */ './views/admin/OtherEmailer.vue'),
            props: { method: 'index', type: 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observerships E-mailer'
            },
          },
          {
            path: '/old-directors-emailer',
            name: 'old_directors_emailer',
            component: () => import(/* webpackChunkName: "old-directors-program-emailer" */ './views/admin/OldDirectorsEmailer.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Old Directors E-mailer'
            },
          },
          {
            path: '/upload-documents',
            name: 'upload_documents',
            component: () => import('./views/admin/UploadDocuments.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Upload Documents'
            },
          },
          {
            path: '/edit-announcement',
            name: 'edit_announcement',
            component: () => import('./views/admin/EditAnnouncement.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Edit Announcement'
            },
          },
          {
            path: '/caf',
            name: 'caf',
            component: () => import(/* webpackChunkName: "caf" */ './views/admin/Caf.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'CAF'
            },
          },
          {
            path: '/caf/:id/edit',
            name: 'caf.edit',
            component: () => import(/* webpackChunkName: "caf-edit" */ './views/CafEdit.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'CAF'
            }
          },
          {
            path: '/caf/:id',
            name: 'caf.show',
            component: () => import(/* webpackChunkName: "caf-show" */ './views/CafShow.vue'),
            props: { method: 'show' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'CAF'
            }
          },
          {
            path: '/public-profiles',
            name: 'public_profiles',
            component: () => import(/* webpackChunkName: "publik-profiles" */ './views/admin/PublicProfiles.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry','admin sjsm'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Public Profiles'
            }
          },
          {
            path: '/public-profiles/:id/edit',
            name: 'public_profiles.edit',
            component: () => import(/* webpackChunkName: "users" */ './views/admin/PublicProfiles.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Public Profiles'
            }
          },
          {
            path: '/admins',
            name: 'admins',
            component: () => import(/* webpackChunkName: "admins" */ './views/admin/Admins.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Admins'
            },
          },
          {
            path: '/admins/:id/edit',
            name: 'admins.edit',
            component: () => import(/* webpackChunkName: "admins" */ './views/admin/Admins.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Admins'
            }
          },
          {
            path: '/specialties',
            name: 'specialties',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/Specialties.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Specialties'
            }
          },
          {
            path: '/fellowship',
            name: 'fellowship',
            component: () => import(/* webpackChunkName: "fellowship-specialties" */ './views/admin/FellowshipSpecialties.vue'),
            props: { method: 'index', type: 'fellowship' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Fellowship Specialties'
            }
          },
          {
            path: '/fellowship/create',
            name: 'fellowship-specialties.create',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/FellowshipSpecialties.vue'),
            props: { method: 'create', type: 'fellowship' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Specialties'
            }
          },
          {
            path: '/fellowship/:id/edit',
            name: 'fellowship-specialties.edit',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/FellowshipSpecialties.vue'),
            props: { method: 'edit', type: 'fellowship' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Fellowship Specialties'
            }
          },
          {
            path: '/research',
            name: 'research-specialties',
            component: () => import(/* webpackChunkName: "research-specialties" */ './views/admin/OtherSpecialties.vue'),
            props: { method: 'index', specialtyType: 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research Specialties'
            }
          },
          {
            path: '/research/create',
            name: 'research-specialties.create',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/OtherSpecialties.vue'),
            props: { method: 'create', specialtyType: 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research Specialties'
            }
          },
          {
            path: '/research/:id/edit',
            name: 'research-specialties.edit',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/OtherSpecialties.vue'),
            props: { method: 'edit', specialtyType: 'research' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Research Specialties'
            }
          },
          {
            path: '/observership',
            name: 'observership-specialties',
            component: () => import(/* webpackChunkName: "observership-specialties" */ './views/admin/OtherSpecialties.vue'),
            props: { method: 'index', specialtyType: 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observership Specialties'
            }
          },
          {
            path: '/observership/create',
            name: 'observership-specialties.create',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/OtherSpecialties.vue'),
            props: { method: 'create', specialtyType: 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observership Specialties'
            }
          },
          {
            path: '/observership/:id/edit',
            name: 'observership-specialties.edit',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/OtherSpecialties.vue'),
            props: { method: 'edit', specialtyType: 'observership' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Observership Specialties'
            }
          },
          {
            path: '/specialties/create',
            name: 'specialties.create',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/Specialties.vue'),
            props: { method: 'create' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Specialties'
            }
          },
          {
            path: '/specialties/:id/edit',
            name: 'specialties.edit',
            component: () => import(/* webpackChunkName: "specialties" */ './views/admin/Specialties.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin','writer','data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Specialties'
            }
          },
          {
            path: '/purchases',
            name: 'purchases',
            component: () => import(/* webpackChunkName: "Discounts" */ './views/admin/Purchases.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Purchases'
            }
          },
          {
            path: '/Discounts',
            name: 'discounts',
            component: () => import(/* webpackChunkName: "Discounts" */ './views/admin/Discounts.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Discounts'
            }
          },
          {
            path: '/discounts/create',
            name: 'discounts.create',
            component: () => import(/* webpackChunkName: "discounts" */ './views/admin/Discounts.vue'),
            props: { method: 'create' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Discounts'
            }
          },
          {
            path: '/discounts/:id/edit',
            name: 'discounts.edit',
            component: () => import(/* webpackChunkName: "discounts" */ './views/admin/Discounts.vue'),
            props: { method: 'edit' },
            meta: {
              auth: {roles: ['admin'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Discounts'
            }
          },
          // USER ROUTES
          {
            path: '/todo-list',
            name: 'todo',
            component: () => import(/* webpackChunkName: "user-todo" */ './views/todo/Todo.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['admin','user', 'writer', 'data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: 'Todo List'
            }
          },
          {
            path: '/buy-programs',
            name: 'buy_programs',
            component: () => import(/* webpackChunkName: "buy-programs" */ './views/user/BuyPrograms.vue'),
            props: { method: 'index' },
            meta: {
              auth: null,
              pageTitle: 'Residency Programs List'
            }
          },
          {
            path: '/cart',
            name: 'cart',
            component: () => import(/* webpackChunkName: "user-programs" */ './views/user/Cart.vue'),
            props: { method: 'index' },
            meta: {
              auth: null
            }
          },
          {
            path: '/my-programs',
            name: 'my_programs',
            component: () => import(/* webpackChunkName: "user-programs" */ './views/user/ProgramsList.vue'),
            props: { method: 'index' },
            meta: {
              auth: null,
              pageTitle: 'Programs'
            }
          },
          {
            path: '/my-old-programs',
            name: 'my_old_programs',
            component: () => import(/* webpackChunkName: "user-programs" */ './views/user/Programs.vue'),
            props: { method: 'index' },
            meta: {
              auth: null,
              pageTitle: 'Programs'
            }
          },
          {
            path: '/my-old-programs/:id',
            name: 'my_programs_show',
            component: () => import(/* webpackChunkName: "user-programs-show" */ './views/user/ProgramsShow.vue'),
            props: { method: 'show' },
            meta: {
              auth: null,
              pageTitle: 'Programs'
            }
          },
          {
            path: '/my-programs/:id',
            name: 'my_programs_by_specialty',
            component: () => import(/* webpackChunkName: "user-programs-by-specialty" */ './views/user/ProgramsBySpecialty.vue'),
            props: { method: 'show' },
            meta: {
              auth: null,
              pageTitle: 'Programs'
            }
          },
          {
            path: '/my-files',
            name: 'my-files',
            component: () => import(/* webpackChunkName: "user-my-files" */ './views/user/MyFiles.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'My files',
            }
          },
          {
            path: '/my-externships',
            name: 'my-externships',
            component: () => import(/* webpackChunkName: "user-externships" */ './views/user/MyExternships.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'Externships'
            }
          },
          {
            path: '/interview-preparation-service',
            name: 'interview-preparation-service',
            component: () => import(/* webpackChunkName: "user-interview" */ './views/user/InterviewPreparationService.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: ''
            }
          },
          {
            path: '/interview-preparation-service/:id',
            name: 'interview-preparation-service-edit',
            component: () => import(/* webpackChunkName: "user-interview-edit" */ './views/user/InterviewPreparationServiceEdit.vue'),
            props: { method: 'index' },
            meta: {
              auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
              pageTitle: ''
            },
          },
          {
            path: '/personal-statement',
            name: 'personal-statement',
            component: () => import(/* webpackChunkName: "user-ps" */ './views/user/PersonalStatement.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'Personal Statement'
            }
          },
          {
            path: '/cv-service',
            name: 'cv_service',
            component: () => import(/* webpackChunkName: "user-cv" */ './views/user/CV.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'CV'
            }
          },
          {
            path: '/my-services',
            name: 'my-services',
            component: () => import(/* webpackChunkName: "user-services" */ './views/user/Services.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'My Services'
            },
          },
          {
            path: '/eras-calculator',
            name: 'eras-calculator',
            component: () => import(/* webpackChunkName: "user-calculator" */ './views/user/Calculator.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user', 'admin', 'writer', 'data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'ERAS Calculator'
            },
          },
          {
            path: '/purchase',
            name: 'purchase',
            component: () => import(/* webpackChunkName: "purchase" */ './views/user/Purchase.vue'),
            meta: {
            auth: null,
            pageTitle: 'Purchase'
            },
          },
          {
            path: '/caf-service',
            name: 'caf-service',
            component: () => import(/* webpackChunkName: "user-caf" */ './views/user/CAFService.vue'),
            props: { method: 'show' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'CAF Service'
            }
          },
          {
            path: '/caf-service/edit',
            name: 'caf-service.edit',
            component: () => import(/* webpackChunkName: "user-caf" */ './views/user/CAFService.vue'),
            props: { method: 'edit' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'CAF Service'
            }
          },
          {
            path: '/free-cv',
            name: 'free-cv',
            component: () => import(/* webpackChunkName: "user-cv-edit" */ './views/user/CVEdit.vue'),
            props: { method: 'edit' },
            meta: {
            auth: {roles: ['user'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'Create your own CV'
            },
          },
          {
            path: '/notifications',
            name: 'notifications',
            component: () => import(/* webpackChunkName: "user-notitifications" */ './views/Notifications.vue'),
            props: { method: 'index' },
            meta: {
            auth: {roles: ['user', 'admin', 'writer', 'data entry'], redirect: {path: '/login'}, forbiddenRedirect: '/403'},
            pageTitle: 'Notifications'
            },
          }          
        ],
      },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
      {
        path: '',
        component: () => import('@/layouts/full-page/FullPage.vue'),
        children: [
      // =============================================================================
      // PAGES
      // =============================================================================
          {
            path: '/pages/error-404',
            name: 'pageError404',
            component: () => import('@/views/pages/Error404.vue')
          },
        ]
      },
      // Redirect to 404 page, if no match found
      {
        path: '*',
        redirect: '/pages/error-404'
      }
    ],
})

export default router
