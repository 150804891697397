/*=========================================================================================
  File Name: main.js
  Description: main vue(js) file
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: hhttp://www.themeforest.net/user/pixinvent
==========================================================================================*/

import 'es6-promise/auto'
import axios from 'axios'
import Vue from 'vue'
import VueAuth from '@websanova/vue-auth'
import VueAxios from 'vue-axios'
import App from './App.vue'
import Vuesax from 'vuesax'
import auth from './auth'
import Form from './utilities/Form';
import Filters from './utilities/Filters'
import './globalComponents.js'
import router from './router'
import store from './store/store'
import 'prismjs'
import Echo from 'laravel-echo'
import Vue2Editor from 'vue2-editor';
// Theme Configurations
import '../themeConfig.js'

// Feather font icon
// require('./assets/css/iconfont.css')

// Set global vars
window.Vue = Vue
window.Form = Form;
window.axios = axios
window.Pusher = require('pusher-js');

// Set Vue router
Vue.router = router

Vue.use(Vuesax)
Vue.use(VueAxios, axios)
Vue.use(VueAuth, auth)
Vue.use(Vue2Editor)

axios.defaults.baseURL = `/`

Vue.config.productionTip = false

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');
if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
}

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: '3b7e23322236c6ef85f1',
  cluster: 'us2',
  encrypted: true,
});

const UNAUTHORIZED = 401;
const FORBIDDEN = 403;
const SERVERERROR = 500;
axios.interceptors.response.use(
  response => response,
  error => {
    const {status} = error.response;
    
    if (status === UNAUTHORIZED && (window.location.pathname.indexOf('/login') == -1 && window.location.pathname.indexOf('/purchase') == -1)) {
      window.location.href = '/login';
      console.log('unauth');
      return false;
    } else if (status === FORBIDDEN) {
      console.log('forbidden');
      // return false;
    } else if (status === SERVERERROR && window.location.pathname.indexOf('/login') == -1) {
      if (error.response.data.exception.indexOf('JWT') !== -1) {
        console.log(error.response);
        console.log('JWT error');
        localStorage.removeItem('laravel-vue-spa');
        document.cookie = 'imgprep_token=; domain=' + process.env.MIX_IMGPREP_DOMAIN +'; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
        document.cookie = 'specialties_to_purchase=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
        document.cookie = 'services_to_purchase=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
        window.location.href = '/login';
        return false;
      }
      console.log(error.response.data.exception);
      console.log('server error');
    } else {
      console.log(error.response.data)
      if (error.response.data.hasOwnProperty('exception') && error.response.data.exception.indexOf('JWT') !== -1) {
        console.log(error.response);
        console.log('JWT error');
        localStorage.removeItem('laravel-vue-spa');
        document.cookie = 'imgprep_token=; domain=' + process.env.MIX_IMGPREP_DOMAIN +'; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
        document.cookie = 'specialties_to_purchase=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
        document.cookie = 'services_to_purchase=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/';
        window.location.href = '/login';
      }
      console.log('else', window.location.pathname, error.response.data.exception, status)
      // return false;
    }
    return Promise.reject(error);
  }
);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
