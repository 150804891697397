<template>
    <div class="mt-12">
        <div class="vx-row mb-2 mt-10">
            <div class="vx-col w-full" >
                <h3 class="mb-4 mt-8">Add a new Program:</h3>

                <p>File must be in the following format (first line will not be accepted):</p>
                <p>Name,Program_Number,Specialty,Program_Director,State,Tel,Fax,Email 1,Program_Coordinator,Email 2,Website,Describes_As,Deadline,Interview_Period,Step 1,Step 2,Step1_Average_Range,J1,H1B,%IMGs</p>

                <br>
                <p>Some considerations</p>
                <ul>
                    <li>- If J1 is required and H1B is NOT the visa status will be J-1</li>
                    <li>- If J1 is NOT required and H1B is required the visa status will be H-1</li>
                    <li>- If J1 is required and H1B is required the visa status will be H-1/J-1</li>
                    <li>- If J1 is NOT required and H1B is NOT required the visa status will be 'Does not sponsor'</li>
                    <li>- If the J1 value or H1B value is empty, the visa status will be kept as it is on the database</li>
                    <li>- If the program exists, it will be updated</li>
                    <li>- If the program does not exist, it will be created</li>
                    <li>- If the program has a website URL it will not be updated through the CSV import</li>
                    <li>- If a program has the frieda equal to 1 it will be ignored when updating</li>
                    <li>- If the deadline date has -- digits it will be replaced to one (-)</li>
                    <li>- When updating if the USMLE step 1 or USML step 2 values from the dabatabe are greater than the CSV values; the values from the database will be taken</li>
                    <li>- Deadlines have special contidionts</li>
                    <li>- Deadlines dates must be in MM/DD/YYYY format</li>
                    <li>- When creating, the program specialty will be set depending on the first three characters of the frieda number </li>
                </ul>

                <div class="vx-row my-5">
                    <div class="vx-col sm:w-1/2 md:w-1/2">
                        <general-file 
                            file=""
                            description=""
                            serverurl="/api/program-import-csv"
                        />
                    </div>
                </div>

                <p>When uploading, please wait until you get a success confirmation message</p>
            </div>
        </div>
    </div>
</template>

<script>
    import GeneralFile from '../../components/crud/GeneralFile';

    export default {
        props : {
            method : String,
        },
        data() {
            return {
                loading : false,
            }
        },
        components: {
            GeneralFile,
        },
        methods: {
        },
        created() {
        }
    }
</script>

<style>
</style>