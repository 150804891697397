/*=========================================================================================
  File Name: moduleTodoMutations.js
  Description: Todo Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
    SET_TODO : (state,payload) => {        
        state.todoArray = payload.tasks;
        state.user = payload.user;
    },
    ADD_TODO(state, taskObj) {
        const newTodo = Object.assign({}, taskObj);
        Vue.set(state.todoArray, newTodo.id, newTodo)  
    },
    UPDATE_TODO(state, taskObj) {
        state.todoArray[taskObj.id].title = taskObj.title;
        state.todoArray[taskObj.id].desc = taskObj.desc;
        state.todoArray[taskObj.id].comment = taskObj.comment;
        state.todoArray[taskObj.id].isDone = taskObj.isDone;
        state.todoArray[taskObj.id].isImportant = taskObj.isImportant;
        state.todoArray[taskObj.id].isStarred = taskObj.isStarred;
        state.todoArray[taskObj.id].tags = taskObj.tags;
        state.todoArray[taskObj.id].isTrashed = taskObj.isTrashed;
        state.todoArray[taskObj.id].attachment = taskObj.attachment;
    },
    SET_TODO_TITLE(state, payload) {
        state.todoArray[payload.id].title = payload.title;
    },
    SET_TODO_DESC(state, payload) {
        state.todoArray[payload.id].desc = payload.desc;
    },
    SET_TODO_COMMENT(state, payload) {
        state.todoArray[payload.id].comment = payload.comment;
    },
    TOGGLE_IS_DONE(state, payload) {
        state.todoArray[payload.id].isDone = payload.value;
        axios.patch('/api/update-user-task/' + payload.id, { checked : payload.value });
    },
    TOGGLE_IS_IMPORTANT(state, payload) {
        state.todoArray[payload.id].isImportant = payload.value;
        axios.patch('/api/update-user-task/' + payload.id, { important : payload.value });
    },
    TOGGLE_IS_STARRED(state, payload) {
        state.todoArray[payload.id].isStarred = payload.value;
        axios.patch('/api/update-user-task/' + payload.id, { starred : payload.value });
    },
    UPDATE_TAGS(state, payload) {
        state.todoArray[payload.id].tags = payload.newTags;
    },
    MOVE_TO_TRASH(state, payload) {
        state.todoArray[payload.id].isTrashed = payload.value;
        axios.patch('/api/update-user-task/' + payload.id, { trashed : payload.value });
    },
    APPLY_TODO_FILTER(state, filterName) {
        state.todoFilter = filterName;
    },
    SET_TODO_SEARCH_QUERY(state, query) {
        state.todoSearchQuery = query;
    }
}