// States
const state = {
  specialties: [],
  specialtiesPremium: [],
  services: [],
}

// mutations
const mutations = {
  SET_SPECIALTIES_TO_PURCHASE(state, payload) {
    state.specialties = JSON.parse(JSON.stringify(payload))
  },
  SET_SPECIALTIES_PREMIUM_TO_PURCHASE(state, payload) {
    state.specialtiesPremium = JSON.parse(JSON.stringify(payload))
  },
  SET_SERVICES_TO_PURCHASE(state, payload) {
    state.services = JSON.parse(JSON.stringify(payload))
  },
  ADD_SERVICE(state, payload) {
    state.services.push(payload)
  },
  REMOVE_SERVICE(state, payload) {
    state.services = state.services.filter((e) => e != payload)
  },
}

// actions
const actions = {
  setSpecialtiesToPurchase({ commit }, data) {
    commit('SET_SPECIALTIES_TO_PURCHASE', data)
  },
  setSpecialtiesPremiumToPurchase({ commit }, data) {
    commit('SET_SPECIALTIES_PREMIUM_TO_PURCHASE', data)
  },
  setServicesToPurchase({ commit }, data) {
    commit('SET_SERVICES_TO_PURCHASE', data)
  },
  addService({ commit }, data) {
    axios.post('/api/service-cart', { service_id: data })
      .then(() => {
        commit('ADD_SERVICE', data)
        this._vm.$vs.notify({
          title:'Success',
          text: 'Added to cart',
          color:'success',
          iconPack: 'feather',
          icon:'icon-check'
        });
      })
      .catch((e) => {
        this._vm.$vs.notify({
          title:'Error',
          text: 'Add to cart failed',
          color:'danger',
          iconPack: 'feather',
          icon:'icon-x'
        });
      })
  },
  removeService({ commit }, data) {
    axios.delete(`/api/service-cart/${data}`)
      .then(() => {
        commit('REMOVE_SERVICE', data)
        this._vm.$vs.notify({
          title:'Success',
          text: 'Removed from cart',
          color:'success',
          iconPack: 'feather',
          icon:'icon-check'
        });
      })
      .catch((e) => {
        this._vm.$vs.notify({
          title:'Error',
          text: 'Remove from cart failed',
          color:'danger',
          iconPack: 'feather',
          icon:'icon-x'
        });
      })
  }
}

// Getters
const getters = {
  specialties: (state) => state.specialties,
  specialtiesPremium: (state) => state.specialtiesPremium,
  services: (state) => state.services
}

export default {
	namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}