/*=========================================================================================
  File Name: moduleTodoActions.js
  Description: Todo Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import router from '../../router';

export default {
    getTodo({ commit }, payload) {
        let uri;

        if (router.currentRoute.params.id) {
            uri = '/api/get-user-tasks/' + router.currentRoute.params.id;
        } else {
            uri = '/api/get-user-tasks';
        }
        axios.get(uri).then(response => {
            commit('SET_TODO',response.data)
        }).catch(e => {
            this.loading = false;
            console.log('could not get results', e);
        });
        
    },
    addTodo({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios.post('/api/tasks', {
                user_id: router.currentRoute.params.id,
                title: payload.title,
                description: payload.desc,
                comment: payload.comment,
                important: payload.isImportant,
                starred: payload.isStarred,
                checked: payload.isDone,
                tags: payload.tags,
                attachment: payload.attachment
            }).then(response => {
                payload.id = response.data.id;
                commit('ADD_TODO', payload);
                resolve(response);
            }).catch((e) => {
                reject(e);
            });
        })
    },
    updateTodo({ commit }, payload) {
        console.log(payload);
        axios.patch('/api/update-user-task/' + payload.id, { 
            title: payload.title,
            description: payload.desc,
            comment: payload.comment,
            important: payload.isImportant,
            starred: payload.isStarred,
            checked: payload.isDone,
            tags: payload.tags,
            attachment: payload.attachment
        }).then(response => {
            console.log('done');
            commit('UPDATE_TODO', payload);
        }).catch(e => {
            let error = e.errors[Object.keys(e.errors)[0]][0];
            if (!error) {
                error = 'An error had occurred';
            } 
            
            this.$vs.notify({
                title:'Error',
                text: error,
                color:'danger',
                iconPack: 'feather',
                icon:'icon-x'});
        });
    },
    toggleIsDone({ commit }, payload) {
        commit('TOGGLE_IS_DONE', payload);
    },
    toggleIsImportant({ commit }, payload) {
        commit('TOGGLE_IS_IMPORTANT', payload);
    },
    toggleIsStarred({ commit }, payload) {
        commit('TOGGLE_IS_STARRED', payload);
    },
    updateTags({ commit }, payload) {
        commit('UPDATE_TAGS', payload);
    },
    moveToTrash({ commit }, payload) {
        commit('MOVE_TO_TRASH', payload);
    },
    applyTodoFilter({ commit }, filterName) {
        commit('APPLY_TODO_FILTER', filterName);
    },
    setTodoSearchQuery({ commit }, query){
        commit('SET_TODO_SEARCH_QUERY', query)
    },
    setTodoTitle({ commit }, payload){
        commit('SET_TODO_TITLE', payload)
    },
    setTodoDesc({ commit }, payload){
        commit('SET_TODO_DESC', payload)
    },
    setTodoComment({ commit }, payload){
        commit('SET_TODO_COMMENT', payload)
    },
}