/*=========================================================================================
  File Name: moduleTodoGetters.js
  Description: Todo Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
    todoArrayLength: state => state.todoArray.length,
    todoList: state => Object.values(state.todoArray).filter((todoItem) => {
        if (state.todoFilter == 'all') return !todoItem.isTrashed && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'starred') return !todoItem.isTrashed && todoItem.isStarred && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'important') return !todoItem.isTrashed && todoItem.isImportant && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'done') return !todoItem.isTrashed && todoItem.isDone && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'trashed') return todoItem.isTrashed && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'volunteer') return !todoItem.isTrashed && todoItem.tags.includes('volunteer') && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'work') return !todoItem.isTrashed && todoItem.tags.includes('work') && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'research') return !todoItem.isTrashed && todoItem.tags.includes('research') && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'miscellaneous') return !todoItem.isTrashed && todoItem.tags.includes('miscellaneous') && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
        if (state.todoFilter == 'update') return !todoItem.isTrashed && todoItem.tags.includes('update') && (todoItem.title.toLowerCase().includes(state.todoSearchQuery.toLowerCase()) || todoItem.desc.toLowerCase().includes(state.todoSearchQuery.toLowerCase()));
    }).reverse(),
    getTodosBySection: state => (sectionId) => state.todoArray.filter((todoItem) => todoItem.sectionId == sectionId),
}