<template>
    <div class="general-file">
        <FilePond 
            @processfile="processFile"
            @init="handleFilePondInit"
            class=""
            ref="pond"
            :key="filepondKey"
            :server="server"
            :files="[]"
        />
    </div>
</template>

<script>
    import vueFilePond from 'vue-filepond';

    import 'filepond/dist/filepond.min.css';
    import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
    

    const FilePond = vueFilePond(
        FilePondPluginFileValidateType
    );

    export default {
        props: {
            file : String,
            additionalFields: Object,
            serverurl : String,
            additionalFields : Object,
            filepondKey : String,
            fileType : {
                type: String,
                required: false
            },
        },
        components: {
            FilePond
        },
        data() {
            return {
                server : {}
            }
        },
        mounted() {
            this.server = {
                url : this.serverurl,
                load: null,
                process: {
                    headers: {
                        'Authorization': 'Bearer ' + this.$auth.token(),
                        'Accept': 'application/json'
                    },
                    method: 'POST',
                    ondata: (formData) => {
                        formData.append('_token', document.querySelector('meta[name="csrf-token"]').getAttribute('content'));
                        formData.append('file', this.fileType);
                        if (this.additionalFields) {
                            Object.keys(this.additionalFields).forEach((key) => {
                                formData.append(key, this.additionalFields[key]);
                            });
                        }

                        return formData;
                    },
                    onerror: (res) => {
                        console.log("errros");
                        return JSON.parse(res).errors
                    }
                }
            };
        },
        methods: {
            handleFilePondInit() {
                // console.log(this.$refs.pond);
            },
            processFile: function(err, file){
                // console.log("Server ID", file.serverId);
                
                let error = '';
                if (err) {
                    try {
                        error = err.body[Object.keys(err.body)[0]][0];
                    } catch(e) {
                        error = 'An error had occurred';    
                    }
                } else {
                    let json = JSON.parse(file.serverId);                    
                    if(typeof(json.file) != "undefined"){
                        let data = json.file;
                       this.$emit('uploadedDoc', data);                       
                    }else if(json == "Uploaded"){                        
                       this.$emit('uploadedDoc',json)
                    } 
                    this.$emit('uploaded',json)
                    setTimeout(() => {
                        this.componentKey += 1;
                    },1000)
                }

                if (error) {
                    this.$vs.notify({
                        title:'Error',
                        text: error,
                        color:'danger',
                        iconPack: 'feather',
                        icon:'icon-x'});
                } else {
                    this.$vs.notify({
                        title:'Success',
                        text: 'The file was uploaded successfully',
                        color:'success',
                        iconPack: 'feather',
                        icon:'icon-check'});
                }
                // console.log("on process ", err, file.serverId);
                
                // console.log("Server ID", file.serverID);
                
            },
            afterMounted() {

            },
            created(){
            }            
        }
    }
</script>

<style>
    .general-file .filepond--root {
        margin: 0;
        width: 100%;
    }
    .filepond--drop-label {
        color: #4c4e53;
    }

    .filepond--label-action {
        text-decoration-color: #babdc0;
    }

    .filepond--panel-root {
        background-color: #edf0f4;
    }

    .filepond--drop-label label {
        cursor:pointer;
    }
</style>
